import * as tingle from 'tingle.js';
import {Dom} from '../base/src/utils/dom';
import {IFooterButton} from './interfaces/IFooterButton';
import './styles/style.css';

type closePositionType = 'inside' | 'outside';

export class Popup {
    private instanceObject;
    private title: string;
    private content: string;
    private footerContent: string;
    private startY: number;
    private modalOffset: number;
    private footer: boolean;
    private stickyFooter: boolean;
    private closeLabel: string = 'Закрыть';
    private cssClasses: string;
    private onOpen: any;
    private onBeforeOpen: any;
    private onClose: any;
    private onBeforeClose: any;
    private hasExit: boolean;
    private closePosition: closePositionType;
    private isCloseOnTouch: boolean = false;
    private isDestroyAfterClose: boolean = false;
    private footerButtons: IFooterButton[] = [];

    /**
     * Конструктор
     * @param {string} cssClasses - класс попапа
     * @param footer
     */
    constructor(cssClasses: string = 'popup', footer: boolean = false) {
        this.cssClasses = cssClasses;
        this.hasExit = true;
        this.footer = footer;
        this.stickyFooter = false;
        this.content = '';
        this.footerContent = '';
        this.startY = 0;
        this.modalOffset = 0;
    }

    get instance() {
        if (typeof this.instanceObject === 'undefined') {

            this.instanceObject = new tingle.modal({
                footer: this.footer,
                stickyFooter: this.stickyFooter,
                closeLabel: this.closeLabel,
                cssClass: this.cssClasses.split(' '),
                onOpen: (): void => this._onOpen(),
                beforeOpen: (): void => this._onBeforeOpen(),
                onClose: (): void => this._onClose(),
                beforeClose: (): boolean => this._beforeClose()
            });

            if (this.title) {
                const titleElement = Dom.createElement(
                    'h2',
                    this.title,
                    'tingle-modal__title'
                );
                this.instanceObject.modalBoxContent.insertAdjacentElement('beforebegin', titleElement);
            }

            if (this.isClosePositionInside(this.closePosition)) {
                const {modalCloseBtn} = this.instanceObject;
                this.instanceObject.modalCloseBtn.remove();
                this.instanceObject.modalBoxContent.insertAdjacentElement('beforebegin', modalCloseBtn);
                this.instanceObject.modalCloseBtn.setAttribute('aria-label', 'Закрыть окно');
            }

            if (this.isCloseOnTouch) {
                this.instanceObject.modalCloseSwipeBtn = Dom.createElement(
                    'button',
                    'Закрыть окно',
                    'tingle-modal__close-swipe'
                );
                this.instanceObject.modalCloseSwipeBtn.setAttribute('aria-label', 'Закрыть окно');
                this.instanceObject.modalBoxContent.insertAdjacentElement(
                    'beforebegin',
                    this.instanceObject.modalCloseSwipeBtn
                );
            }

        }
        return this.instanceObject;
    }

    /**
     * Вызов попапа
     * @param {number} timeout - задержка показа
     */
    public show(timeout: number = 0): void {
        this._setContent();
        setTimeout((): void => {
            this.instance.open();
            if (this.isCloseOnTouch) {
                this.addEventListenersTouch();
            }
        }, timeout);
    }

    public open(timeout: number = 0): void {
        setTimeout((): void => {
            this.instance.open();
            if (this.isCloseOnTouch) {
                this.addEventListenersTouch();
            }
        }, timeout);
    }

    public close(timeout: number = 0): void {
        setTimeout((): void => {
            this.instance.close();
            if (this.isCloseOnTouch) {
                this.removeEventListenersTouch();
            }
        }, timeout);
    }

    /**
     * Добавление кнопки в футер
     * @param {string} label - текст
     * @param {string} cssClass - класс
     * @param event - событие
     */
    public addFooterButton(label: string, cssClass?: string, event?: any) {
        this.footer = true;
        this.instance.addFooterBtn(label, cssClass, event);
    }

    /**
     * Добавить кнопку в массив кнопок футера. При вызове метода show кнопки добавятся в инстанс попапа
     * @param button
     */
    public addButtonInFooter(button: IFooterButton): this {
        this.footerButtons.push(button);
        return this;
    }

    /**
     * Установка настроек
     * @param {boolean} footer - показывать футер
     * @param {boolean} stickyFooter - липкий футер
     * @param {string} closeLabel - заголовок кнопки закрыть
     */
    public setSettings(footer: boolean = false, stickyFooter: boolean = false,
                       closeLabel: string = 'Закрыть'): this {
        this.footer = footer;
        this.stickyFooter = stickyFooter;
        this.closeLabel = closeLabel;

        return this;
    }

    /**
     * Добавление события при открытии
     * @param onOpen
     */
    public addEventOpen(onOpen: any): void {
        if (typeof onOpen === 'function') {
            this.onOpen = onOpen;
        }
    }

    /**
     * Добавление события после открытия
     * @param onBeforeOpen
     */
    public addEventBeforeOpen(onBeforeOpen: any): void {
        if (typeof onBeforeOpen === 'function') {
            this.onBeforeOpen = onBeforeOpen;
        }
    }

    /**
     * Добавление события при закрытии
     * @param onClose
     */
    public addEventClose(onClose: any): this {
        if (typeof onClose === 'function') {
            this.onClose = onClose;
        }
        return this;
    }

    /**
     * Добавление событий touch
     */
    public addEventListenersTouch() {
        this.instanceObject.modalCloseSwipeBtn.addEventListener('touchstart', (e) => this.handleTouchStart(e));
        this.instanceObject.modalCloseSwipeBtn.addEventListener('touchmove', (e) => this.handleTouchMove(e));
        this.instanceObject.modalCloseSwipeBtn.addEventListener('touchend', () => this.handleTouchEnd());
    }

    /**
     * Удаление событий touch
     */
    public removeEventListenersTouch() {
        this.instanceObject.modalCloseSwipeBtn.removeEventListener('touchstart', (e) => this.handleTouchStart(e));
        this.instanceObject.modalCloseSwipeBtn.removeEventListener('touchmove', (e) => this.handleTouchMove(e));
        this.instanceObject.modalCloseSwipeBtn.removeEventListener('touchend', () => this.handleTouchEnd());
    }

    /**
     * Добавление стилей при событий touch
     */
    public addStylesToTouchMove() {
        this.instanceObject.modalBox.style.cssText = `
          transition: none;
          bottom: ${this.modalOffset}px;
        `;
    }

    /**
     * Удаление стилей при закрытии модального окна
     */
    public removeStylesToTouchMove() {
        this.instanceObject.modalBox.style = '';
    }

    /**
     * Установка положение касания экрана
     */
    public handleTouchStart(e) {
        this.startY = e.touches[0].clientY;
    }

    /**
     * Установка положение модального окна при касании
     */
    public handleTouchMove(e) {
        const offset = this.startY - e.touches[0].clientY;
        this.modalOffset = offset < 0 ? offset : 0;
        this.addStylesToTouchMove();
    }

    /**
     * Завершение touch события
     */
    public handleTouchEnd() {
        if (this.modalOffset < -40) {
            this.close();
            this.startY = 0;
        }
        this.modalOffset = 0;
        this.removeStylesToTouchMove();
    }

    /**
     * Добавлениея события до закрытия
     * @param onBeforeClose
     */
    public addEventBeforeClose(onBeforeClose: any): void {
        if (typeof onBeforeClose === 'function') {
            this.onBeforeClose = onBeforeClose;
        }
    }

    /**
     * Блокировка закрытия
     */
    public blockExit() {
        this.hasExit = false;
    }

    /**
     * Разблокировка закрытия
     */
    public unBlockExit() {
        this.hasExit = true;
    }

    /**
     * Установка заголовка
     * @param title
     */
    public setTitle(title: string): this {
        this.title = title;
        return this;
    }

    /**
     * Установка контента
     * @param {string} content - текст
     */
    public setContent(content: string): this {
        this.content = content;
        return this;
    }

    public setInstanceContent(content: string): this {
        this.content = content;
        this._setContent();
        return this;
    }

    /**
     * Установка контента в футере
     * @param {string} footerContent - текст
     */
    public setFooterContent(footerContent: string): this {
        this.footerContent = footerContent;
        return this;
    }

    /**
     * Установка позизии кнопки закрытия
     * @param closePosition
     */
    public setClosePosition(closePosition: closePositionType = 'outside'): this {
        this.closePosition = closePosition;
        return this;
    }

    /**
     * Установка условия для закрытия по тач-событию
     * @param value
     */
    public setIsCloseOnTouch(value: boolean = false): this {
        this.isCloseOnTouch = value;
        return this;
    }

    /**
     * Установка условия для удаления попапа из DOM после закрытия
     * @param value
     */
    public setIsDestroyOnClose(value: boolean = false): this {
        this.isDestroyAfterClose = value;
        return this;
    }

    /**
     * Установка sticky footer
     * @param value
     */
    public setStickyFooter(value: boolean = false): this {
        this.stickyFooter = value;
        return this;
    }

    /**
     * Получение контента
     * @returns {string}
     */
    public getContent(): string {
        return this.instance.getContent();
    }

    /**
     * Получение контента из футера
     * @returns {string}
     */
    public getFooterContent(): string {
        return this.instance.getFooterContent();
    }

    /**
     * Вернет true если вытота попапа больше чем высота viewport
     * @returns {boolean}
     */
    public isOverflow(): boolean {
        return this.instance.isOverflow();
    }

    /**
     * Allow to reposition the modal (useful with asynchronous content)
     * @returns {boolean}
     */
    public checkOverflow(): void {
        this.instance.checkOverflow();
    }

    /**
     * Применение всего контента
     * @private
     */
    private _setContent(): void {
        this.instance.setContent(this.content);

        if (this.footerContent.length && this.footer) {
            this.instance.setFooterContent(this.footerContent);
        }

        if (this.footerButtons.length) {
            this.footerButtons.forEach((button: IFooterButton) => {
                const {label, cssClass, callback} = button;
                this.addFooterButton(label, cssClass, callback);
            });
        }
    }

    /**
     * Событие закрытия
     * @private
     */
    private _onClose(): void {
        if (typeof this.onClose === 'function') {
            this.onClose();
        }

        if (this.isDestroyAfterClose) {
            this.instance.destroy();
        }
    }

    /**
     * Событие открытия
     * @private
     */
    private _onOpen(): void {
        if (typeof this.onOpen === 'function') {
            this.onOpen();
        }
    }

    /**
     * Событие после открытия
     * @private
     */
    private _onBeforeOpen(): void {
        if (typeof this.onBeforeOpen === 'function') {
            this.onBeforeOpen();
        }
    }

    /**
     * Событие до закрытия
     * @returns {boolean} - (если true, то сработает закрытие попапа)
     * @private
     */
    private _beforeClose(): boolean {
        if (typeof this.onBeforeClose === 'function') {
            this.onBeforeClose();
        }

        return this.hasExit;
    }

    private isClosePositionInside(position: closePositionType): position is closePositionType {
        return position === 'inside';
    }
}
